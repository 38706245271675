<template>
  <div class="papas-merge-card">
    <div class="top">
      <div class="questions-details">

        <div class="title-container" v-if="knownCardNumber">
          <div class="title">בשביל שהתזרים יהיה מדויק, צריך פירוט של הכרטיס:</div>
          <div class="account">{{ formattedCardNumber }}</div>
          <img class="logo" v-if="hasSource" :src="require(`@/assets/bank-logos-30/${logoFileName}`)">
          <div class="lower-title">רוצה להוסיף את הפרטים שלו?</div>
        </div>

        <div class="title-container" v-else>
        <span class="title">
          <template v-if="isCard">כרטיס <span :class="{ bold: hasSource }">{{formattedSource}}</span>!</template>
          <template v-else-if="!isCard">המערכת זיהתה {{question.billingAmount ? 'חיוב' : 'זיכוי' }} אשראי
            <div class="source">
              <template v-if="hasSource">
                <div class="formatted-source bold">{{formattedSource}} </div>
                <div v-if="formattedAccount">(בחשבון {{formattedAccount}})</div>
              </template>
              <div v-else>בחשבון {{formattedAccount}}</div>
            </div>
          </template>
        </span>
          <div class="amount bold">
            {{ amount }}
            <span class="nis-currency">₪</span>
          </div>
          <div class="description">
            <div>{{ question.businessName }}</div>
            <div>{{ normalizedBillingDate }}</div>
          </div>
          <div v-if="isCard" class="lower-title">בשביל שהתזרים יהיה מדויק, צריך את הפירוט שלו, רוצה להוסיף את הפרטים?</div>
        </div>

      </div>

      <div class="question-actions">
        <template v-if="isCard">
          <riseup-button class="button-container" :variant="'primary'" :size="'slim'" :title="'כן! אני רוצה להוסיף'"
                         :action="() => setAddCreds()"/>
          <riseup-button class="button-container" :variant="'secondary'" :size="'slim'" :title="'אני לא רוצה להוסיף'"
                         :action="() => setInputAndTrack(papaInputTypes.approved)"/>
        </template>

        <template v-else-if="question.billingAmount">
          <riseup-button class="button-container" :variant="'secondary'" :size="'slim'" :title="'זה כרטיס אשראי'" :subtitle="'אני רוצה להוסיף אותו'"
                         :action="() => setAddCreds()"/>
          <riseup-button class="button-container" :variant="'secondary'" :size="'slim'" :title="'זה כרטיס אשראי'"
                         :subtitle="'אני לא רוצה להוסיף אותו'" :action="() => setInputAndTrack(papaInputTypes.approved)"/>
          <riseup-button class="button-container" :variant="'secondary'" :size="'slim'" :title="'זה החזר הלוואה'"
                         :action="() => setInputAndTrack(papaInputTypes.loan)"/>
        </template>

        <template v-else>
          <riseup-button class="button-container" :variant="'secondary'" :title="'זה כרטיס אשראי'" :subtitle="'אני רוצה להוסיף אותו'"
                         :action="() => setAddCreds()" :size="'slim'"/>
          <riseup-button class="button-container" :variant="'secondary'" :title="'זה כרטיס אשראי'"  :subtitle="'אני לא רוצה להוסיף אותו'"
                         :action="() => setInputAndTrack(papaInputTypes.approved)" :size="'slim'"/>
          <riseup-button class="button-container" :variant="'secondary'" :title="'זה זיכוי אשראי'"
                         :action="() => setInputAndTrack(papaInputTypes.clearing)" :size="'slim'"/>
        </template>
      </div>
    </div>
    <separator/>
    <riseup-button v-if="jitPapasMerge" class="riseup-button" :id="footerButton.id"
                   :action="footerButton.action"
                   :size="'slim'"
                   :title="footerButton.title"
                   :variant="footerButton.variant"
                   :icon="footerButton.icon"/>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { getSourceConfigurationBySourceName, sortedCardSources } from '@/constants/sourcesConfiguration';
import BaseUI from '@riseupil/base-ui';
import Intercom from '@/Intercom';
import AddCredentialsFlow from '@/pages/responsive-pages/authenticated/navigation-pages/credentials-settings/add-credentials-flow/AddCredentialsFlow';
import moneyUtils from '../../../../../../../utils/money';
import dateUtils from '../../../../../../../utils/dates';
import Segment from '../../../../../../../Segment';
import ReportBug from '../../../../../../../mixins/ReportBug';

export default {
  name: 'PapasMergeCard',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    Separator: BaseUI.Separator,
  },
  mixins: [ReportBug],
  props: ['question'],
  data() {
    return {
      isCard: false,
      addCreds: false,
      eventName: 'PapasInvestigationClicked',
      eventData: {
        question: {
          amount: this.amount,
          transactionId: this.question.transactionId,
          businessName: this.question.businessName,
          billingDate: this.question.billingDate,
        },
      },
    };
  },
  async created() {
    this.isCard = !!this.cardNumber;
    Segment.trackUserInteraction('JustInTimeCX_Shown', { source: this.$options.name, isButtonShown: this.jitPapasMerge });
  },
  computed: {
    ...mapGetters('featureFlags', ['jitPapasMerge']),
    ...mapState('mergeInvestigationQuestions', ['papaInputTypes']),
    cardNumber() {
      // We match 4 digits that are not preceded by a dot (.) to avoid matching a date (1.1.2023)
      const cardNumberMatch = this.question.businessName.match(/(?<!\.)\d{4}/);
      return cardNumberMatch && cardNumberMatch[0].slice(-4);
    },
    knownCardNumber() {
      return !!this.cardNumber;
    },
    cardSource() {
      return this.question.cardSource;
    },
    logoFileName() {
      return getSourceConfigurationBySourceName(this.question.cardSource).logoFile;
    },
    formattedCardNumber() {
      return `${this.cardNumber} ********`;
    },
    hasSource() {
      return !!(this.question.cardSource && getSourceConfigurationBySourceName(this.question.cardSource));
    },
    formattedSource() {
      const cardPrefix = this.isCard ? 'אשראי' : 'חב\' אשראי';
      return this.hasSource ? getSourceConfigurationBySourceName(this.question.cardSource).displayName : cardPrefix;
    },
    formattedAccount() {
      return this.question.accountNumberPiiValue;
    },
    cardSources() {
      return sortedCardSources();
    },
    amount() {
      const amount = this.question.billingAmount ? this.question.billingAmount : this.question.incomeAmount;
      return moneyUtils.formatAmountWithFraction(amount);
    },
    normalizedBillingDate() {
      return dateUtils.normalizeDate(this.question.billingDate);
    },
    footerButton() {
      return {
        title: 'לצ׳אט עם הצוות',
        variant: 'tertiary',
        color: BaseUI.LayoutConsts.COLOR_VARIANT.BLACK,
        action: this.handleIntercomClickAction,
        // eslint-disable-next-line global-require
        icon: require('@/assets/icons/chat_icon_teriatry.svg'),
        id: 'cx-just-in-time-papas-merge-footer-button',
      };
    },
  },
  methods: {
    ...mapActions('mergeInvestigationQuestions', ['setPapaQuestionInput', 'setPapaSource']),
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    ...mapMutations('mergeInvestigationQuestions', ['setSourceNameToAdd', 'setOnCredentialsAddSuccess']),
    setAddCreds() {
      this.addCreds = true;
      this.onAddCredentialsSource();
    },
    addCardSource({ source }) {
      Segment.trackUserInteraction('PapasMergeCard', { source, businessName: this.question.businessName });
      this.setPapaSource({ transactionId: this.question.transactionId, cardSource: source.sourceName });
      this.onAddCredentialsSource();
    },
    setInputAndTrack(input) {
      this.trackInput(input);
      this.setPapaQuestionInput({ question: this.question, input });
    },
    trackInput(input) {
      this.trackInputInSegment(this.eventName, {
        ...this.eventData,
        papaAccountHasBankCc: this.question.papaAccountHasBankCc,
        input,
      });
    },
    onAddCredentialsSource() {
      const input = this.papaInputTypes.addCreds;
      this.trackInput(input);
      this.setSourceNameToAdd(this.question.cardSource);
      this.openModal({
        component: AddCredentialsFlow,
        props: {
          sourceNameToAdd: this.cardSource,
          allowBackToSourceSelection: false,
          knownSourceType: 'card',
        },
        eventHandlers: {
          success: () => {
            this.setPapaQuestionInput({ question: this.question, input });
          },
        },
        showLastModal: true,
        popupAlignment: 'full-screen',
      });
    },
    handleIntercomClickAction() {
      Segment.trackUserInteraction('JustInTimeCX_Clicked', { source: this.$options.name });
      Intercom.showNewMessage('');
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../../../../../scss/mixins';

  .papas-merge-card {
    border-radius: 8px;
    margin-bottom: 16px;

    .top {
      padding: 16px 24px;
      .questions-details {
        margin-bottom: 35px;

        .title-container {
          text-align: right;
          line-height: 28px;

          .icon {
            position: relative;
            bottom: -1px;
            margin-left: 5px;
          }

          .title {
            margin-right: auto;
            font-size: 22px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
          }

          .source {
            margin-top: 10px;

            .formatted-source {
              line-height: 28px
            }
          }

          .lower-title {
            font-size: 20px;
            margin: 15px 0 0;
            line-height: 1.1em;
          }

          .account {
            font-weight: bold;
            font-size: 35px;
            margin: 20px 0 0;
          }
          .logo {
            width: 112px;
            margin: 4px 0 0;
          }
          .bold {
            font-weight: bold;
          }
        }

        .amount {
          line-height: 35px;
          font-weight: bold;
          font-size: 30px;
          margin: 20px 2px 12px;

          .nis-currency {
            font-size: 18px;
          }

          &.long {
            font-size: 42px;

            .nis-currency {
              font-size: 30px;
            }
          }
        }

        .description {
          line-height: 1.2;
          font-size: 16px;
          color: $riseup_gray_2;
        }
      }

      .question-actions {
        .button-container {
          margin-bottom: 16px;
        }
      }
    }

    .merge-separator{
      height: 10px;
    }
}
</style>
