<script>
import Intercom from '../Intercom';
import Segment from '../Segment';

export default {
  name: 'ReportBug',
  methods: {
    trackInputInSegment(eventName, eventData) {
      Segment.trackUserInteraction(eventName, eventData);
    },
    async reportBug(eventName, eventData) {
      this.trackInputInSegment(eventName, eventData);
      Intercom.show();
    },
  },
};

</script>
