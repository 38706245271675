<template>
  <div class="notification">
    <component :is="activeNotificationComponent"/>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import TechnicalErrorNotification from './notification-types/TechnicalErrorNotification.vue';
import DormantNotification from './notification-types/DormantNotification.vue';
import SystemBugNotification from './notification-types/SystemBugNotification.vue';
import AccountQuestionsNotification from './notification-types/AccountQuestionsNotification.vue';
import MergeQuestionsNotification from './notification-types/MergeQuestionsNotification.vue';
import UpdateCredentialsNotification from './notification-types/UpdateCredentialsNotification.vue';
import CustomerBugNotification from './notification-types/CustomerBugNotification.vue';
import MaliciousNumberNotification from './notification-types/MaliciousNumberNotification.vue';
import ProcessingAnswers from './notification-types/ProcessingAnswers.vue';
import LoadingCredentialsNotification
  from './notification-types/LoadingCredentialsNotification';
import BankCcBugNotification from './notification-types/BankCcBugNotification.vue';

export default {
  name: 'Notification',
  components: {
    TechnicalErrorNotification,
    DormantNotification,
    SystemBugNotification,
    UpdateCredentialsNotification,
    CustomerBugNotification,
    AccountQuestionsNotification,
    MergeQuestionsNotification,
    MaliciousNumberNotification,
    ProcessingAnswers,
    BankCcBugNotification,
  },
  computed: {
    ...mapState('applicationState', ['cashflowState', 'notificationState']),
    ...mapGetters('applicationState', ['notificationTypes']),
    notificationToComponent() {
      return {
        [this.notificationTypes.TECHNICAL_ERROR]: TechnicalErrorNotification,
        [this.notificationTypes.TRIAL_EXPIRED]: DormantNotification,
        [this.notificationTypes.DORMANT_AFTER_CANCELED]: DormantNotification,
        [this.notificationTypes.SYSTEM_BUG]: SystemBugNotification,
        [this.notificationTypes.UPDATE_CREDENTIALS]: UpdateCredentialsNotification,
        [this.notificationTypes.CUSTOMER_BUG]: CustomerBugNotification,
        [this.notificationTypes.ACCOUNT_QUESTIONS]: AccountQuestionsNotification,
        [this.notificationTypes.MERGE_QUESTIONS]: MergeQuestionsNotification,
        [this.notificationTypes.MALICIOUS_MEMBER_JOINED]: MaliciousNumberNotification,
        [this.notificationTypes.PROCESSING_CASHFLOW]: ProcessingAnswers,
        [this.notificationTypes.LOADING_CREDENTIALS]: LoadingCredentialsNotification,
        [this.notificationTypes.BANK_CC_BUG]: BankCcBugNotification,
      };
    },
    activeNotificationComponent() {
      return this.notificationToComponent[this.notificationState];
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
