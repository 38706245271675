import { render, staticRenderFns } from "./SonsMergeCard.vue?vue&type=template&id=4afd574c&scoped=true&"
import script from "./SonsMergeCard.vue?vue&type=script&lang=js&"
export * from "./SonsMergeCard.vue?vue&type=script&lang=js&"
import style0 from "./SonsMergeCard.vue?vue&type=style&index=0&id=4afd574c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4afd574c",
  null
  
)

export default component.exports