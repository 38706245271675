<script>
import { mapActions } from 'vuex';
import UnsupportedBanksDecisionPopup from './UnsupportedBanksDecisionPopup.vue';

export default {
  name: 'ShowUnsupportedModalMixin',
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    showUnsupportedPopup(source) {
      this.openModal({
        component: UnsupportedBanksDecisionPopup,
        props: { source },
      });
    },
  },
};
</script>
