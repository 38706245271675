<template>
  <div>
    <app-notification :title="'מעולה, אין לנו עוד שאלות.'"
                      :text="'אנחנו נעדכן את התזרים בהתאם ונעדכן אותך בוואטסאפ ברגע שיהיה מוכן.'"
                      :notification-buttons="[
                        {
                          title:'הבנתי, תודה!',
                          size: 'slim',
                          variant: 'primary',
                          action: onClose,
                        },
                        ]"
                      :icon-type="'warning'"/>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import Segment from '../../../../../../../Segment';
import AppNotification from '../AppNotification.vue';

export default {
  name: 'ProcessingAnswers',
  components: {
    AppNotification,
  },
  async created() {
    Segment.trackUserGot('ProcessingAnswerNotificationShown');
  },
  methods: {
    ...mapMutations('applicationState', ['setNotificationState']),
    onClose() {
      this.setNotificationState(null);
    },
  },
};

</script>

<style lang="scss" scoped>
</style>
