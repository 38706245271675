<template>
  <div>
    <app-notification :title="'נראה שחיבור החשבון לוקח יותר זמן מהרגיל.'"
                      :text="'המערכת שלנו על זה, נעדכן אותך בוואטסאפ כשהתזרים מוכן.'"
                      :footer-button="jitLoadingCredentials ? footerButton : null"
                      :icon-type="'danger'"/>
  </div>
</template>

<script>
import Intercom from '@/Intercom';
import { mapGetters } from 'vuex';
import Segment from '../../../../../../../Segment';
import AppNotification from '../AppNotification.vue';

export default {
  name: 'LoadingCredentialsNotification',
  components: {
    AppNotification,
  },
  async created() {
    Segment.trackUserGot('LoadingCredentialsNotification');
  },
  computed: {
    ...mapGetters('featureFlags', ['jitLoadingCredentials']),
    footerButton() {
      return {
        title: 'לצ׳אט עם הצוות',
        variant: 'tertiary',
        action: this.handleIntercomClickAction,
        // eslint-disable-next-line global-require
        icon: require('@/assets/icons/chat_icon_teriatry.svg'),
        id: 'cx-loading-creds-footer-button',
      };
    },
  },
  methods: {
    handleIntercomClickAction() {
      Segment.trackUserInteraction('LoadingCredentials_CX_Clicked', {});
      Intercom.showNewMessage('');
    },
  },
};

</script>

<style lang="scss" scoped>
</style>
