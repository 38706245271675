<template>
  <div class="papas-merge-closed-card-container">
    <closed-card class="papas-merge-closed-card" :class="{ 'danger': isBug }"
                 :title="title"
                 :sub-title="cardSubtitle" />
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';
import { mapState } from 'vuex';
import moneyUtils from '../../../../../../../utils/money';

export default {
  name: 'PapasMergeClosedCard',
  components: {
    ClosedCard: BaseUI.ClosedCard,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('mergeInvestigationQuestions', ['papaInputTypes']),
    title() {
      if (this.hasSource) {
        return this.cardSourceName;
      }
      const typeOfAmount = this.question.billingAmount ? 'חיוב' : 'זיכוי';
      return `${typeOfAmount} של ${this.amount}`;
    },
    cardSubtitle() {
      if (this.question.input === this.papaInputTypes.approved) {
        return 'הכרטיס לא יתווסף';
      }
      if (this.question.input === this.papaInputTypes.clearing) {
        return 'החיוב סומן כזיכוי';
      }
      if (this.question.input === this.papaInputTypes.loan) {
        return 'החיוב סומן כהלוואה';
      }
      if (this.isBug) {
        return 'תקלה דווחה';
      }
      if (this.question.input === this.papaInputTypes.addCreds) {
        return 'הכרטיס התווסף בהצלחה';
      }
      return null;
    },
    isBug() {
      return this.question.input === this.papaInputTypes.bug;
    },
    cardSourceName() {
      const source = getSourceConfigurationBySourceName(this.question.cardSource);
      return source ? source.displayName : '';
    },
    hasSource() {
      return !!this.question.cardSource;
    },
    amount() {
      const amount = this.question.billingAmount ? this.question.billingAmount : this.question.incomeAmount;
      return moneyUtils.formatAmountWithFraction(amount);
    },

  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>

.papas-merge-closed-card-container {
  padding: 0 16px;
  .papas-merge-closed-card {
    margin-bottom: 8px;
  }
}

</style>
