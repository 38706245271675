import { render, staticRenderFns } from "./PapasMergeCard.vue?vue&type=template&id=4395220b&scoped=true&"
import script from "./PapasMergeCard.vue?vue&type=script&lang=js&"
export * from "./PapasMergeCard.vue?vue&type=script&lang=js&"
import style0 from "./PapasMergeCard.vue?vue&type=style&index=0&id=4395220b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4395220b",
  null
  
)

export default component.exports