<template>
  <papas-merge-card
    v-if="!papaInvestigation.input"
    class="open-card"
    :key="papaInvestigation.transactionId"
    :question="papaInvestigation"
    @close="closeModal"/>
  <papas-merge-closed-card v-else
                           :question="papaInvestigation"
                           :key="papaInvestigation.transactionId"/>
</template>
<script>
import { mapActions } from 'vuex';
import PapasMergeCard from './PapasMergeCard';
import PapasMergeClosedCard from './PapasMergeClosedCard';

export default {
  name: 'papa-investigation',
  components: { PapasMergeCard, PapasMergeClosedCard },
  props: {
    papaInvestigation: {},
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
  },
};
</script>

<style lang="scss" scoped>
.open-card {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
