<template>
    <div class="account-investigator-card">
      <div class="top">
        <div class="card-top">
          <div class="card-title">
            <span class="title">{{ accountIdentificationHeader }}</span>
          </div>
          <div class="card-text">
            {{ question.accountNumberPiiValue }}
          </div>
          <div>
            <img v-if="sourceTypeToLogoFile" :src="require(`@/assets/bank-logos/${sourceTypeToLogoFile}`)" class="logo">
          </div>
        </div>
        <div class="buttons">
          <riseup-button class="button-container"
                         :action="() => setAccountStatusClicked(accountStatuses.include)"
                         :title="'תוסיף אותו לתזרים'"
                         :size="'slim'"
                         :variant="'primary'"/>
          <riseup-button class="button-container"
                         :action="() => setAccountStatusClicked(accountStatuses.exclude)"
                         :title="'אל תוסיף אותו לתזרים'"
                         :size="'slim'"
                         :variant="'secondary'"/>
        </div>
      </div>
      <separator/>
      <card-bug-report
        class="bug-report-wrapper"
        @close="closeModal()"
        :action="() => reportBug(this.eventName, { ...this.eventData })"/>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';
import Segment from '../../../../../../../Segment';
import CardBugReport from '../../../../../../../base-components/card-parts/CardBugReport.vue';
import ReportBug from '../../../../../../../mixins/ReportBug';

export default {
  name: 'AccountInvestigationCard',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    Separator: BaseUI.Separator,
    CardBugReport,
  },
  mixins: [ReportBug],
  created() {
    this.eventData = {
      question: {
        account: this.question.accountNumberPiiValue,
        sourceType: this.question.sourceType,
        source: this.question.source,
      },
      answer: this.accountStatuses.notApproved,
    };
  },
  data() {
    return {
      eventName: 'AccountInvestigationClicked',
      eventData: {},
    };
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('accountInvestigationQuestions', ['noUnansweredAccountQuestions']),
    accountStatuses() {
      return {
        include: 'include',
        exclude: 'exclude',
        notApproved: 'notApproved',
      };
    },
    account() {
      return this.question.accountNumberPiiValue;
    },
    accountIdentificationHeader() {
      return 'זיהיתי חשבון בנק:';
    },
    sourceTypeToLogoFile() {
      return getSourceConfigurationBySourceName(this.question.source).logoFile;
    },
  },
  methods: {
    ...mapActions('accountInvestigationQuestions', [
      'setAccountStatus',
    ]),
    closeModal() {
      this.$emit('close');
    },
    async setAccountStatusClicked(accountStatus) {
      Segment.trackUserInteraction('AccountInvestigationClicked', {
        question: {
          account: this.question.accountNumberPiiValue,
          sourceType: this.question.sourceType,
          source: this.question.source,
        },
        answer: accountStatus,
      });
      await this.setAccountStatus({
        accountStatus,
        accountDetails: this.question,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../../../../../scss/mixins';

  .account-investigator-card {
    border-radius: 8px;
    box-shadow: 0 5px 10px $riseup_gray_0;
    margin-bottom: 16px;

    .top {
      padding: 16px 24px;

      .card-top {
        margin-bottom: 16px;

        .card-title {
          line-height: 1;
          margin-bottom: 12px;

          .title {
            font-size: 20px;
          }
        }

        .card-text {
          font-weight: bold;
          line-height: 35px;
          text-align: right;
          font-size: 22px;
          color: $riseup_black;
          margin-bottom: 20px;
        }

        .logo {
          text-align: right;
          height: 40px;
          margin-bottom: 5px;
        }
      }

      .buttons {
        .button-container {
          margin-bottom: 16px;
        }
      }
    }
    .card.identified {
      .card-title {
        position: relative;
        left: 4px;
      }
    }
    }
</style>
