<template>
  <div v-if="iconType !== 'none'">
    <img v-if="iconType === 'danger'" :src="require('../../../../../../assets/icons/alert-icon.svg')">
    <img v-else-if="iconType === 'warning'" :src="require('../../../../../../assets/icons/warning-icon.svg')">
 </div>
</template>

<script>

export default {
  name: 'NotificationIcon',
  props: {
    iconType: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
