<template>
  <popup
    :close-action="popupClosed"
    :buttons="[{
      title: 'הבנתי, תודה',
      action: this.continueWithoutSelected,
      variant: 'secondary',
      size: 'slim',
    }]">
    <template v-slot:title>
      <img class="generic-failure-asset-in-popup" :src="require('@/assets/bank-connection-failure.png')">
      <div class="popup-title">{{ popupTitle }}</div>
    </template>
    <template v-slot:content>
      <div class="description">
        {{ popupText }}
      </div>
    </template>
  </popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '../../../../Segment';

export default {
  name: 'UnsupportedBanksDecisionPopup',
  components: {
    Popup: BaseUI.Popup,
  },
  props: ['source'],
  mounted() {
    Segment.trackUserGot('UnsupportedBanksPopupEntered', { sourceName: this.source.sourceName });
  },
  computed: {
    popupTitle() {
      if (this.source.displayName === 'בנק יהב') {
        return 'מצטערים, אבל באופן זמני לא ניתן להכניס פרטי בנק יהב';
      }
      if (this.source.displayName === 'ONE ZERO') {
        return 'לצערנו ONE ZERO עוד לא תומך בחיבור לרייזאפ';
      }
      return `לצערנו המערכת לא תומכת כרגע בחיבור ל${this.source.displayName}`;
    },
    popupText() {
      if (this.source.displayName === 'ONE ZERO') {
        return 'נעדכן אותך בווטסאפ בחודשים הקרובים כשהחיבור יתאפשר.';
      }
      return 'נעדכן אותך בווטסאפ ברגע שהחיבור יתאפשר.';
    },
  },
  methods: {
    continueWithoutSelected() {
      Segment.trackUserInteraction('ContinueWithoutBankClicked', {
        sourceName: this.source.sourceName,
      });
      this.closeModal();
    },
    popupClosed() {
      Segment.trackUserInteraction('UnsupportedBanksDecisionClosed', {
        sourceName: this.source.sourceName,
      });
      this.closeModal();
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  .popup-title {
    text-align: center;
    padding: 0 18px 0 18px;
  }

  .logo-image {
    width: 100%;
    text-align: center;
    height: 110px;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .description {
    margin-bottom: 20px;
  }
</style>
