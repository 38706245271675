<template>
  <closed-card class="account-investigation-closed-card" :class="cardType"
               :title="formattedAccount"
               :sub-title="cardSubtitle" />
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'AccountInvestigationClosedCard',
  components: {
    ClosedCard: BaseUI.ClosedCard,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedAccount() {
      return `${this.question.accountNumberPiiValue.slice(-5)} ...`;
    },
    accountStatuses() {
      return {
        include: 'include',
        exclude: 'exclude',
        notApproved: 'notApproved',
      };
    },
    cardType() {
      if (this.question.accountStatus === this.accountStatuses.exclude) {
        return 'disabled';
      }
      if (this.question.accountStatus === this.accountStatuses.include) {
        return 'success';
      }
      return null;
    },
    cardSubtitle() {
      if (this.question.accountStatus === this.accountStatuses.exclude) {
        return 'החשבון לא כלול בתזרים';
      }
      if (this.question.accountStatus === this.accountStatuses.include) {
        return 'החשבון כלול בתזרים';
      }
      return null;
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>

  .account-investigation-closed-card {
    margin-bottom: 8px;
  }
</style>
