<template>
  <div>
    <app-notification :title="title"
                      :notification-buttons="[
                        {
                          title:'לדיוק התזרים',
                          variant: 'primary',
                          size: 'slim',
                          action: openInvestigation,
                        },
                      ]"
                      :icon-type="'warning'">
      <template v-slot:text>
        <div v-if="!hasSonsMergeQuestions">
          <span>
  כדי לראות את פירוט ההוצאות המלא והמדויק שלך, חשוב להשלים את
          </span>
          <span class="bold">
             חיבור כל כרטיסי האשראי לשירות
          </span>
        </div>
        <div v-else>
          כדי להכין לך תזרים מדויק, נצטרך תשובות לכמה שאלות. זה יקח רק דקה.
        </div>
      </template>
    </app-notification>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex';
import MergeInvestigation
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/investigations/merge-investigation-in-app/MergeInvestigation.vue';
import Segment from '../../../../../../../Segment';
import AppNotification from '../AppNotification.vue';

export default {
  name: 'MergeQuestionsNotification',
  components: {
    AppNotification,
  },
  data() {
    return {
      openMergeInvestigationFlow: false,
    };
  },
  async created() {
    Segment.trackUserGot('MergeQuestionsNotificationShown');
  },
  computed: {
    ...mapState('mergeInvestigationQuestions', ['sonsMergeQuestions']),
    ...mapGetters('applicationState', ['barTypes', 'notificationTypes']),
    title() {
      return this.hasSonsMergeQuestions ? 'יש לנו כמה שאלות...' : undefined;
    },
    hasSonsMergeQuestions() {
      return this.sonsMergeQuestions.length > 0;
    },
  },
  methods: {
    ...mapMutations('applicationState', ['setBarState', 'setNotificationState']),
    ...mapActions('modalRootStore', ['openModal']),
    openInvestigation() {
      this.openModal({
        component: MergeInvestigation,
      });
    },
    onDoneInvestigation() {
      this.setNotificationState(this.notificationTypes.PROCESSING_CASHFLOW);
      this.setBarState(this.barTypes.PROCESSING);
    },
  },
};

</script>

<style lang="scss" scoped>

.bold {
  font-weight: bold;
}

</style>
