<template>
  <div>
    <app-notification :title="'דיווחת לנו על תקלה...'"
                      :text="'הצוות שלנו עובד לתקן אותה מהר! נשלח לך לינק לתזרים מעודכן ברגע שהיא תיפתר.'"
                      :icon-type="'danger'"/>
  </div>
</template>

<script>
import Segment from '../../../../../../../Segment';
import AppNotification from '../AppNotification.vue';

export default {
  name: 'CustomerBugNotification',
  components: {
    AppNotification,
  },
  async created() {
    Segment.trackUserGot('CustomerBugNotificationShown');
  },
};

</script>

<style lang="scss" scoped>
</style>
