<template>
  <div class="update-credentials-notification">
    <app-notification :title="invalidReasonCopy.title"
                      :text="invalidReasonCopy.text"
                      :notification-buttons="notificationButtons"
                      :footer-button="jitUpdateCredentials ? footerButton : null"
                      :icon-type="'danger'"/>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import _ from 'lodash';
import Intercom from '@/Intercom';
import InvalidCredsConsts from '@/constants/invalid-creds';
import customerState from '@/constants/customer-state';
import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';
import Segment from '../../../../../../../Segment';
import AppNotification from '../AppNotification.vue';
import router from '../../../../../../../router';

export default {
  name: 'UpdateCredentialsNotification',
  components: {
    AppNotification,
  },
  async created() {
    Segment.trackUserInteraction('JustInTimeCX_Shown', { source: this.$options.name, isButtonShown: this.jitUpdateCredentials });
    await this.fetchCredentials();
    Segment.trackUserGot('UpdateCredentialsNotificationShown', { reason: this.invalidReason });
    if (this.invalidCreds.length === 0) {
      this.setNotificationState(customerState.NOTIFICATION_STATE.NONE);
    }
  },
  computed: {
    ...mapGetters('credentials', ['invalidCredentialsList']),
    ...mapGetters('featureFlags', ['jitUpdateCredentials']),
    invalidCreds() {
      return _.chain(this.invalidCredentialsList)
        .filter(c => c.status === 'passwordInvalid')
        .sortBy(c => c.statusExtraData?.reason)
        .value();
    },
    firstInvalidCreds() {
      return this.invalidCreds[0] || {};
    },
    sourceType() {
      const { sourceName } = this.firstInvalidCreds;
      return getSourceConfigurationBySourceName(sourceName).type;
    },
    isOpenBanking() {
      const { openBankingConsentId } = this.firstInvalidCreds;
      return !!openBankingConsentId;
    },
    sourceName() {
      const { displayName } = this.firstInvalidCreds;
      return `${this.sourceType === 'bank' ? 'בנק ' : ''}${displayName || ''}`;
    },
    invalidReason() {
      if (this.invalidCreds?.length > 1) {
        return InvalidCredsConsts.InvalidReasons.Multiple;
      }
      return this.firstInvalidCreds?.statusExtraData?.reason;
    },
    invalidReasonCopy() {
      if (this.isOpenBanking) {
        return {
          title: `נראה שההסכמה לחשבון ב${this.sourceName} לא בתוקף.`,
          text: `צריך לחדש אותה באתר או באפליקציה של ${this.sourceName}.`,
          buttons: 'לחידוש ההסכמה',
        };
      }
      const sourceType = `${this.sourceType === 'bank' ? 'הבנק' : 'חברת האשראי'}`;
      switch (this.invalidReason) {
      case InvalidCredsConsts.InvalidReasons.Multiple:
        return {
          title: 'יש בעיה עם פרטי החשבונות.',
          text: 'אי אפשר לעדכן את התזרים כי חלק מפרטי החשבונות שאצלנו לא עובדים.',
          buttons: 'לעדכון פרטים',
        };
      case InvalidCredsConsts.InvalidReasons.AccountIsBlockedTag:
        return {
          title: ` למערכת אין גישה לחשבון ב${this.sourceName}.`,
          text: `ולכן התזרים לא מעודכן. צריך לדבר עם התמיכה של ${sourceType}.`,
          buttons: 'כבר סידרתי את זה',
        };
      case InvalidCredsConsts.InvalidReasons.RequiresPasswordChange:
        return {
          title: `נראה שהסיסמה לחשבון ב${this.sourceName} לא בתוקף`,
          text: `צריך לחדש אותה באתר ${sourceType} ואז לעדכן בשירות.`,
          buttons: 'לעדכון פרטים',
        };
      case InvalidCredsConsts.InvalidReasons.InvalidCredentialsTag:
        return {
          title: `נראה שאחד מפרטי החיבור לחשבון ב${this.sourceName} השתנה.`,
          text: 'צריך לעדכן את הפרטים.',
          buttons: 'לעדכון פרטים',
        };
      default:
        return {
          title: `יש בעיה עם החיבור לחשבון ב${this.sourceName}.`,
          text: 'נראה שצריך לעדכן את הפרטים.',
          buttons: 'לעדכון פרטים',
        };
      }
    },
    footerButton() {
      return {
        title: 'לצ׳אט עם הצוות',
        variant: 'tertiary',
        color: BaseUI.LayoutConsts.COLOR_VARIANT.BLACK,
        action: this.handleIntercomClickAction,
        // eslint-disable-next-line global-require
        icon: require('@/assets/icons/chat_icon_teriatry.svg'),
        id: 'cx-just-in-time-footer-button',
      };
    },
    notificationButtons() {
      return [
        {
          title: this.invalidReasonCopy.buttons,
          variant: 'primary',
          size: 'slim',
          action: this.goToCredentialsSettings,
        },
      ];
    },
  },
  methods: {
    ...mapMutations('applicationState', ['setNotificationState']),
    ...mapActions('credentials', ['fetchCredentials']),
    goToCredentialsSettings() {
      Segment.trackUserInteraction('goToCredentialsSettingsClicked');
      router.push({ path: '/credentials' });
    },
    handleIntercomClickAction() {
      Segment.trackUserInteraction('JustInTimeCX_Clicked', { source: this.$options.name });
      Intercom.showNewMessage('היי רייזאפ,'
          + 'אשמח לעזרה עם עדכון החשבון שלי 🙏');
    },
  },
};

</script>

<style lang="scss" scoped>
.update-credentials-notification {
  ::v-deep .riseup-button .button-content .button-icon {
    display: inline-block;
    margin-left: 6px;
  }
}
</style>
