<template>
  <div class="merge-investigation">
    <loader v-if="loading"/>
    <div class="close-button-container">
      <img class="close-button" :class="{ 'disabled': loading }" :src="require('@/assets/icons/ic-close.svg')" v-on:click="closeModal">
    </div>
    <div class="cards-container">
      <papa-investigation v-for="papaQuestion in papasMergeQuestions" :papa-investigation="papaQuestion" :key="papaQuestion.transactionId"/>
      <template v-for="sonsQuestion in sonsMergeQuestions">
        <sons-merge-card v-if="!sonsQuestion.input"
                         class="open-card"
                         :key="sonsQuestion.chunkId"
                         :question="sonsQuestion"
                         @close="closeModal()"/>
        <sons-merge-closed-card v-else
                                :question="sonsQuestion"
                                :key="sonsQuestion.chunkId"/>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import PapaInvestigation
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/investigations/merge-investigation-in-app/PapaInvestigation';
import Segment from '../../../../../../../Segment';
import SonsMergeCard from './SonsMergeCard.vue';
import SonsMergeClosedCard from './SonsMergeClosedCard.vue';
import Loader from '../../../../../../../base-components/Loader.vue';

export default {
  name: 'MergeInvestigation',
  components: {
    PapaInvestigation,
    Loader,
    SonsMergeCard,
    SonsMergeClosedCard,
  },
  created() {
    Segment.trackUserGot('MergeInvestigatorEntered');
  },
  computed: {
    ...mapGetters('mergeInvestigationQuestions', [
      'allQuestionsAnswered',
    ]),
    ...mapState('mergeInvestigationQuestions', [
      'papasMergeQuestions',
      'sonsMergeQuestions',
      'loading',
    ]),
  },
  methods: {
    ...mapActions('mergeInvestigationQuestions', ['finishMergeInvestigation']),
    async closeModal() {
      if (!this.loading) {
        this.$emit('close');
      }
    },
    async doneAnswering() {
      this.$emit('done');
      this.closeModal();
      Segment.trackUserGot('MergeInvestigationDoneAnswering');
    },
  },
};
</script>

<style lang="scss" scoped>
  .merge-investigation {
    text-align: right;
    margin-top: 16px;

    .close-button-container {
      display: flex;
      justify-content: flex-end;
      margin: 0 16px 20px 16px;

      .close-button {
        cursor: pointer;

        &.disabled {
          cursor: default;
        }
      }
    }

    .cards-container {
      .open-card {
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
    .done-btn {
      margin-top: 32px;
      width: 100%;
    }
  }
</style>
