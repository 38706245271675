<template>
  <div class="bank-cc-bug-modal">
    <coloredPopup class='danger' :close-action="closePopup">
      <template v-slot:top-content>
        <div class="header">
          זיהינו פער במידע שנרשם בבנק לגבי הוצאות האשראי שלך
        </div>
      </template>
      <template v-slot:bottom-content>
        <div class="content">
          <div class="content-bold">
            עד עכשיו המידע על הוצאות האשראי עבר כמו שצריך, אבל כרגע יש פערים.
          </div>
          <div class="content-text">
            המידע בחברות האשראי הוא הכי אמין, ולכן הדרך הכי טובה לפתור את הבעיה היא לחבר את כרטיס האשראי דרך חברת
            האשראי.
          </div>

          <riseup-button
            class="btn"
            title="לחיבור כרטיס אשראי"
            size="slim"
            :bold="true"
            :action="connectCard"/>

          <riseup-button v-if="jitBankCcBug"
            id="cx-bank-cc-creds-connect"
            class="btn"
            :variant="'secondary'"
            title="לצ׳אט עם הצוות"
            :icon="require('@/assets/icons/chat_icon_small_teriatry.svg')"
            size="slim"
            :bold="true"
            :action="requestHelp"/>
        </div>
      </template>
    </coloredPopup>
  </div>
</template>

<script>
import Segment from '@/Segment';
import _ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Intercom from '@/Intercom';
import AddCredentialsFlow
  from '@/pages/responsive-pages/authenticated/navigation-pages/credentials-settings/add-credentials-flow/AddCredentialsFlow';

export default {
  name: 'BankCcBugModal',
  async created() {
    Segment.trackUserGot('BankCcBugModalLoaded');
    await this.fetchMergeQuestions();
  },
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
  },
  computed: {
    ...mapGetters('featureFlags', ['jitBankCcBug']),
    ...mapState('mergeInvestigationQuestions', [
      'sourceNameToAdd',
      'onCredentialsAddSuccess',
      'papasMergeQuestions',
      'sonsMergeQuestions',
    ]),
    getSourceName() {
      const sons = this.sonsMergeQuestions;
      const papas = this.papasMergeQuestions;
      return _.find(sons, q => q.ccScraperSource)?.sourceName
        || _.find(papas, q => q.papaAccountHasBankCc)?.cardSource;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    ...mapActions('mergeInvestigationQuestions', ['fetchMergeQuestions']),
    closePopup() {
      Segment.trackUserGot('BankCcBugModalClosed');
      this.closeModal();
    },
    connectCard() {
      Segment.trackUserGot('BankCcBugModalConnectCardClicked');
      this.closeModal();
      this.openModal({
        component: AddCredentialsFlow,
        props: {
          sourceNameToAdd: this.getSourceName,
          knownSourceType: 'card',
          allowBackToSourceSelection: false,
        },
        eventHandlers: {
          success: () => {
            this.onCredentialsAddSuccess();
          },
        },
        popupAlignment: 'full-screen',
      });
    },
    async requestHelp() {
      this.closeModal();
      Segment.trackUserGot('BankCcBugModalRequestHelpClicked', { sourceType: 'card', sourceName: this.getSourceName });
      Intercom.showNewMessage();
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.bank-cc-bug-modal {
  text-align: right;

  .close-button {
    width: 100%;
  }

  .header {
    color: $riseup_white;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
  }

  .content {
    text-align: right;
    font-size: 18px;
    line-height: 26px;

    .content-bold {
      font-weight: bold;
      margin-bottom: 26px;
    }

    .content-text {
      margin-bottom: 15px;
    }

    .btn {
      margin-top: 15px;
    }
  }
}
</style>
