<template>
  <card :has-footer-padding="false" id="app-notification" class="app-notification">
    <img v-if="showHeader" class="header" :src="headerPath" />
    <div class="notification-content">
      <notification-icon v-if="!showHeader" class="notification-icon" :icon-type="iconType"/>
      <div class="notification-text-wrapper">
        <div class="notification-title">{{ title }}</div>
        <div class="notification-text">
          <slot name="text"/>
          {{ text }}
        </div>
      </div>
      <template v-if="hasButtons">
        <div v-for="button in notificationButtons" :key="button.title" class="button-container">
          <riseup-button class="riseup-button"
                         :action="button.action"
                         :size="button.size || 'slim'"
                         :title="button.title"
                         :subtitle="button.subTitle"
                         :loading="button.loading"
                         :variant="button.variant"/>
        </div>
      </template>
    </div>
    <template v-slot:footer>
      <div v-if="footerButton" class="footer" :id="footerButton.id">
        <riseup-button class="riseup-button"
                       :action="footerButton.action"
                       :color="footerButton.color"
                       :title="footerButton.title"
                       :variant="footerButton.variant"
                       :icon="footerButton.icon"/>
      </div>
    </template>
  </card>
</template>

<script>
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import NotificationIcon from './NotificationIcon.vue';

export default {
  name: 'AppNotification',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    Card: BaseUI.Card,
    NotificationIcon,
  },
  props: {
    showHeader: {
      type: Boolean,
      default: false,
    },
    fromBoti: {
      type: Boolean,
      required: false,
      default: true,
    },
    title: {
      type: String,
      required: false,
    },
    text: {
      type: String,
    },
    notificationButtons: {
      type: Array,
      required: false,
      default: () => [],
    },
    iconType: {
      type: String,
      required: false,
      default: 'none',
    },
    headerPath: {
      type: String,
      required: false,
      default: 'none',
    },
    footerButton: {
      type: Object,
      required: false,
    },
  },
  computed: {
    hasButtons() {
      return !_.isEmpty(this.notificationButtons);
    },
  },
};
</script>

<style lang="scss">
  @import '@riseupil/base-ui/src/scss/border-radius';
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../../../../scss/mixins';

  #app-notification {
    background-color: $riseup_white;

    .card-padding {
      padding: 0;
    }

    img.header {
      border-radius: $popup-border-radius $popup-border-radius 0 0;
      width: 100%;
    }

    .notification-content {
      font-family: SimplerPro;
      color: $riseup_black;
      padding: 25px 24px;
      .notification-icon {
        margin-bottom: 15px;
      }

      .notification-text-wrapper {
        color: $riseup_black;

        .notification-title {
          font-family: SimplerPro;
          font-size: 20px;
          font-weight: bold;
          line-height: 28px;
          margin-bottom: 10px;
        }

        .notification-text {
          font-size: 20px;
          line-height: 28px;
        }
      }
      .button-container {
        width: 100%;
        padding-top: 25px;

        .riseup-button {
          width: 100%;
        }
      }
    }
    .footer {
      display: flex;
      flex-direction: column;
    }
  }
</style>
