<template>
  <div class="sons-merge-card">
    <div class="top">
      <div class="questions-details">
        <div class="title-container">
          <span class="title">חסר חשבון בנק שקשור לכרטיס:</span>
        </div>
        <div class="account">{{ formattedAccount }}</div>
        <img class="logo" :src="require(`@/assets/bank-logos-30/${logoFileName}`)">
        <div class="title lower-title">רוצה להוסיף אותו לתזרים?</div>
      </div>
      <div v-if="!shouldAddSource" class="question-actions">
        <riseup-button class="button-container" :variant="'primary'" :size="'slim'" :title="'לחיבור חשבון בנק'"
                       :action="() => setAddBankSource()"/>
        <riseup-button class="button-container" :variant="'secondary'" :size="'slim'" :title="'להוציא את הכרטיס מהתזרים'"
                       :action="() => setInputAndTrack(cardInputTypes.excluded)"/>
      </div>
      <div v-else>
        <source-selection
            :disabled="false"
            :selectionText="'באיזה בנק החשבון?'"
            :sources="sortedCombinedBankSources"
            :sourceType="'bank'"
            :scrollable="true"
            :onlyExpanded="true"
            :addSource="setSource">
        </source-selection>
      </div>
    </div>
    <separator/>
    <riseup-button class="riseup-button" :id="footerButton.id"
                   :action="footerButton.action"
                   :size="'slim'"
                   :title="footerButton.title"
                   :variant="footerButton.variant"
                   :icon="footerButton.icon"/>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import BankAccounts from '@/mixins/BankAccounts';
import { mapActions, mapMutations, mapState } from 'vuex';
import Segment from '@/Segment';
import Intercom from '@/Intercom';
import AddCredentialsFlow
  from '@/pages/responsive-pages/authenticated/navigation-pages/credentials-settings/add-credentials-flow/AddCredentialsFlow';
import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';
import SourceSelection from '../../../../../../../base-components/credentials/credentials-selection-card/SourceSelection.vue';
import ReportBug from '../../../../../../../mixins/ReportBug';

export default {
  name: 'SonsMergeCard',
  components: {
    SourceSelection,
    RiseupButton: BaseUI.RiseupButton,
    Separator: BaseUI.Separator,
  },
  mixins: [ReportBug, BankAccounts],
  props: ['question'],
  data() {
    return {
      addBankSource: false,
      eventName: 'SonsInvestigationClicked',
      eventData: {
        question: {
          chunkId: this.question.chunkId,
          account: this.question.account,
          source: this.question.source,
          billingDate: this.question.billingDate,
        },
      },
    };
  },
  async created() {
    Segment.trackUserInteraction('JustInTimeCX_Shown', { source: this.$options.name });
  },
  computed: {
    ...mapState('mergeInvestigationQuestions', ['cardInputTypes']),
    logoFileName() {
      return getSourceConfigurationBySourceName(this.question.source).logoFile;
    },
    formattedAccount() {
      const revealed = this.question.accountNumberPiiValue;
      return `${revealed} ********`;
    },
    shouldAddSource() {
      return this.addBankSource;
    },
    footerButton() {
      return {
        title: 'לצ׳אט עם הצוות',
        variant: 'tertiary',
        color: BaseUI.LayoutConsts.COLOR_VARIANT.BLACK,
        action: this.handleIntercomClickAction,
        // eslint-disable-next-line global-require
        icon: require('@/assets/icons/chat_icon_teriatry.svg'),
        id: 'cx-just-in-time-sons-merge-footer-button',
      };
    },
  },
  methods: {
    ...mapActions('mergeInvestigationQuestions', ['setSonQuestionInput', 'addSonSource']),
    ...mapActions('modalRootStore', ['openModal']),
    ...mapMutations('mergeInvestigationQuestions', ['setSourceNameToAdd', 'setOnCredentialsAddSuccess']),
    setAddBankSource() {
      this.addBankSource = true;
    },
    setInputAndTrack(input) {
      this.trackInput(input);
      this.setSonQuestionInput({ chunkId: this.question.chunkId, input });
    },
    trackInput(input) {
      this.trackInputInSegment(this.eventName, { ...this.eventData, input });
    },
    setSource({ source }) {
      this.addSonSource({ chunkId: this.question.chunkId, source });
      const input = this.cardInputTypes.addCreds;
      this.trackInput(input);
      this.setSourceNameToAdd(source.sourceName);
      this.openModal({
        component: AddCredentialsFlow,
        props: {
          sourceNameToAdd: source.sourceName,
          allowBackToSourceSelection: false,
          knownSourceType: 'bank',
        },
        eventHandlers: {
          success: () => {
            this.setSonQuestionInput({ chunkId: this.question.chunkId, input });
          },
        },
        showLastModal: true,
        popupAlignment: 'full-screen',
      });
    },
    handleIntercomClickAction() {
      Segment.trackUserInteraction('JustInTimeCX_Clicked', { source: this.$options.name });
      Intercom.showNewMessage('');
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../../../../../scss/mixins';

  .sons-merge-card {
    border-radius: 8px;
    box-shadow: 0 5px 10px $riseup_gray_0;
    margin-bottom: 16px;

    .top {
      padding: 16px 24px;
      .questions-details {
        margin-bottom: 16px;

        .title-container {
          line-height: 1em;
          margin-bottom: 12px;

          .icon {
            position: relative;
            bottom: -1px;
            margin-left: 5px;
          }
        }

        .title {
          font-size: 20px;
          line-height: 20px;
        }
        .lower-title {
          margin: 18px 0 0;
        }

        .account {
          line-height: 1em;
          font-weight: bold;
          font-size: 35px;
          margin: 18px 0 0;
        }

        .logo {
          height: 40px;
        }
      }

      .question-actions {
        .button-container {
          margin-bottom: 16px;
        }
      }
    }
}

</style>
