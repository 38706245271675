<template>
  <div class="sons-merge-closed-card-container">
    <closed-card class="papas-merge-closed-card" :class="{ 'danger': isBug }"
                 :title="title"
                 :sub-title="cardSubtitle" />
  </div>
</template>

<script>
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import { mapState } from 'vuex';

export default {
  name: 'SonsMergeClosedCard',
  components: {
    ClosedCard: BaseUI.ClosedCard,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('sourceFields', ['allSourceFields']),
    ...mapState('mergeInvestigationQuestions', ['cardInputTypes']),
    title() {
      if (this.hasSource) {
        return this.bankSourceName;
      }
      return this.formattedAccount;
    },
    formattedAccount() {
      return `${this.question.accountNumberPiiValue}****`;
    },
    cardSubtitle() {
      if (this.question.input === this.cardInputTypes.excluded) {
        return 'החשבון של הכרטיס לא יתווסף';
      }
      if (this.isBug) {
        return 'תקלה דווחה';
      }
      if (this.question.input === this.cardInputTypes.addCreds) {
        return 'החשבון חובר בהצלחה';
      }
      return null;
    },
    isBug() {
      return this.question.input === this.cardInputTypes.bug;
    },
    bankSourceName() {
      const source = _.find(this.allSourceFields, source => source.sourceName === this.question.bankSource);
      return source ? source.displayName : '';
    },
    hasSource() {
      return !!this.question.bankSource;
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>

.sons-merge-closed-card-container {
  padding: 0 16px;
  .papas-merge-closed-card {
    margin-bottom: 8px;
  }
}
</style>
