<template>
  <div>
    <app-notification :title="'יש בעיה בתזרים'"
                      :text="'המידע שהגיע מהבנק על ההוצאות בכרטיסי האשראי שלך לא עדכני.'"
                      :icon-type="'danger'"
                      :notification-buttons="[
                        {
                          title:'מה אפשר לעשות?',
                          variant: 'primary',
                          size: 'slim',
                          action: openBankCcBugModal,
                        },
                      ]"/>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Segment from '../../../../../../../Segment';
import AppNotification from '../AppNotification.vue';
import BankCcBugModal from './modals/BankCcBugModal.vue';

export default {
  name: 'BankCcBugNotification',
  components: {
    AppNotification,
  },
  async created() {
    Segment.trackUserGot('BankCcBugNotificationShown');
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    openBankCcBugModal() {
      Segment.trackUserInteraction('OpenBankCcBugModalClicked');
      this.openModal({
        component: BankCcBugModal,
      });
    },
  },
};

</script>
<style lang="scss" scoped>
</style>
