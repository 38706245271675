<template>
  <div>
    <app-notification :title="title"
                      :text="text"
                      :notification-buttons="[
                        {
                          title: buttonTitle,
                          variant: 'primary',
                          size: 'small',
                          action: updatePayment,
                        },
                      ]"
                      :header-path="image"
                      :show-header="true"/>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { SUBSCRIPTION_STATES } from '@/constants/subscription-state';
import promotions from '@/constants/promotions';
import Segment from '../../../../../../../Segment';
import AppNotification from '../AppNotification.vue';
import router from '../../../../../../../router';
import DDLogs from '../../../../../../../DDLogs';

const config = {
  [promotions.BENEFIT_TYPES.FREE_MONTHS]: {
    // eslint-disable-next-line global-require
    image: require('@/assets/dormant/short-term-dormant-months-free.png'),
    titleGetter: ({ monthsText }) => `אפשר להמשיך ליהנות מהשרות עם ${monthsText} בחינם!`,
    text: 'כדי לממש את ההטבה ולהמשיך להיות על זה צריך לבחור סוג מינוי.',
    buttonTitle: 'למימוש ההטבה',
  },
  [promotions.BENEFIT_TYPES.PERCENTAGE_DISCOUNT]: {
    // eslint-disable-next-line global-require
    image: require('@/assets/dormant/short-term-dormant-percentage.png'),
    titleGetter: ({ monthsText, benefitPercentage }) => `אפשר להמשיך ליהנות מהשרות עם ${monthsText} ב־${benefitPercentage}% הנחה!`,
    text: 'כדי לממש את ההטבה ולהמשיך להיות על זה צריך לבחור סוג מינוי.',
    buttonTitle: 'למימוש ההטבה',
  },
  previousVariant: {
    // eslint-disable-next-line global-require
    image: require('@/assets/cashflow-locked.svg'),
    titleGetter: () => 'השירות הוקפא והתזרים הפסיק להתעדכן.',
    text: 'כדי להמשיך לקבל תזרים חודשי, צריך לעדכן את פרטי התשלום.',
    buttonTitle: 'לעדכון פרטי תשלום',
  },
  default: {
    // eslint-disable-next-line global-require
    image: require('@/assets/dormant/short-term-dormant-default.png'),
    titleGetter: () => 'אפשר להמשיך להיות בטוב עם הכסף שלך!',
    text: 'כדי שהתזרים ימשיך להתעדכן, רק צריך לבחור סוג מינוי ולעדכן את פרטי התשלום.',
    buttonTitle: 'לבחירת מינוי',
  },
};

export default {
  name: 'DormantNotification',
  components: {
    AppNotification,
  },
  async created() {
    Segment.trackUserGot('DormantNotification', {
      subscriptionState: this.subscriptionState,
      benefitType: this.benefitType || 'no-benefit',
      benefitPercentage: this.benefitPercentage,
      newShortTermDormant: this.newShortTermDormant,
    });
  },
  computed: {
    ...mapState('subscriptionState', ['subscriptionState']),
    ...mapState('benefits', ['benefitType', 'benefitPercentage', 'redeemed']),
    ...mapGetters('benefits', ['monthsText', 'isHundredDaysPromotion']),
    ...mapGetters('featureFlags', ['newShortTermDormant']),
    showNewShortTermDormantExperience() {
      return this.newShortTermDormant && !this.redeemed;
    },
    variant() {
      if (this.isHundredDaysPromotion) {
        return config.default;
      }
      const configType = this.showNewShortTermDormantExperience ? (this.benefitType || 'default') : 'previousVariant';
      return config[configType];
    },
    title() {
      return this.variant.titleGetter({ monthsText: this.monthsText, benefitPercentage: this.benefitPercentage });
    },
    text() {
      return this.variant.text;
    },
    image() {
      return this.variant.image;
    },
    buttonTitle() {
      return this.variant.buttonTitle;
    },
  },
  methods: {
    openSubscriptionPage() {
      Segment.trackUserInteraction('OpenSubscriptionPageClicked');
      router.push({ path: '/subscription' });
    },
    gotoAccountSettings() {
      router.push({ path: '/account-settings' });
    },
    updatePayment() {
      switch (this.subscriptionState) {
      case SUBSCRIPTION_STATES.DORMANT_AFTER_CANCELED:
        this.gotoAccountSettings();
        break;
      case SUBSCRIPTION_STATES.DORMANT_AFTER_TRIAL:
        this.openSubscriptionPage();
        break;
      default: DDLogs.error(`Unexpected subscription state in dormant notification: ${this.subscriptionState}`);
      }
    },
  },
};

</script>

<style lang="scss" scoped>
</style>
