<template>
  <div class="card-selection">
    <div class="selection-title" v-bind:class="{ 'selection-title-open': expanded, 'disabled': disabled, 'only-expanded': onlyExpanded }"
         v-on:click="!onlyExpanded && toggleExpand()">
      <span class="selection-text">{{ selectionText }}</span>
      <template v-if="!onlyExpanded">
        <img v-if="disabled" :src="require('@/assets/icons/add-disabled.svg')">
        <img v-else-if="expanded" :src="require('@/assets/icons/remove.svg')">
        <img v-else :src="require('@/assets/icons/add.svg')">
      </template>
    </div>
    <div class="expanded" :class="expanded && expandedOpenClass">
      <div class="logos" :class="{ scrollable: scrollable }">
        <div v-for="(source, index) in sources" v-bind:key="index" v-on:click="addSourceAndCollapse(source)">
          <div :class="[isSourceCard(source) ? 'card-logo-container' : 'bank-logo-container']">
            <img class="logo" v-if="isSourceCard(source)" :src="require(`@/assets/cards/${source.cardLogo}`)">
            <img class="logo" v-else :src="require(`@/assets/bank-logos/${source.logoFile}`)">
            <img v-if="isSourceCard(source)" class="logo-icon" :src="require(`@/assets/bank-logos/${source.logoFileStacked}`)">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Segment from '../../../Segment';
import ShowUnsupportedModalMixin from '../../../pages/responsive-pages/authenticated/unsupported-banks/ShowUnsupportedModalMixin.vue';

export default {
  name: 'SourceSelection',
  mixins: [ShowUnsupportedModalMixin],
  data() {
    return {
      expanded: false,
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    scrollable: Boolean,
    selectionText: String,
    sources: Array,
    addSource: Function,
    maxHeight: String,
    sourceType: String,
    onlyExpanded: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.expanded = this.onlyExpanded;
  },
  computed: {
    expandedOpenClass() {
      return this.scrollable ? 'expanded-open-scrollable' : 'expanded-open';
    },
  },
  methods: {
    toggleExpand() {
      if (!this.expanded) {
        if (this.sourceType === 'card') {
          Segment.trackUserInteraction('AddCardClicked');
        } else {
          Segment.trackUserInteraction('AddBankClicked');
        }
      }
      if (!this.disabled && !this.onlyExpanded) {
        this.expanded = !this.expanded;
      }
    },
    addSourceAndCollapse(source) {
      if (this.sourceType === 'card') {
        Segment.trackUserInteraction('CardNameClicked', { name: source.sourceName });
      } else {
        Segment.trackUserInteraction('BankNameClicked', { name: source.sourceName });
      }
      if (this.isSourceUnSupported(source)) {
        this.showUnsupportedPopup(source);
      } else {
        this.addSource({ source });
        this.toggleExpand();
      }
    },
    isSourceUnSupported(source) {
      return source && source.unsupported;
    },
    isSourceCard(source) {
      return source.type === 'card';
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  .card-selection {
    .selection-title {
      font-weight: bold;
      color: $riseup_blue;
      font-size: 21px;
      height: 52px;
      padding: 0 30px 0 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      transition: box-shadow 1s;
      &.only-expanded {
        cursor: auto;
      }

      &.selection-title-open {
        margin: 0 0 4px;
        border-radius: 2px;
        box-shadow: 0 3px 4px 0 $riseup_gray_1;
      }

      &.disabled {
        color: $riseup_gray_1;
        cursor: default;
      }

      .selection-text {
        width: 100%;
      }
    }

    .expanded {
      overflow: hidden;
      max-height: 0;
      transition: max-height 1s;

      &.expanded-open-scrollable {
        max-height: 400px;
      }
      &.expanded-open {
        max-height: 600px;
      }
    }

    .logos {
      display: flex;
      flex-direction: column;
      direction: ltr;
      &.scrollable {
        height: 230px;
        overflow-y: scroll;
      }

      .bank-logo-container {
        border: 0.5px solid $riseup_gray_0;
        border-top: 0;
        width: 100%;
        cursor: pointer;
        text-align: center;

        .logo {
          height: 66px;
          padding: 5px 0;
        }
      }

      .card-logo-container {
        border: 0.5px solid $riseup_gray_0;
        border-top: 0;
        width: 100%;
        height: 131px;
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .logo {
          padding: 0 0 0 16px;
        }

        .logo-icon {
          display: flex;
          align-items: center;
          padding: 0 30px 0 26px;
          img {
            width: 86px;
          }
        }
      }
    }
  }

</style>
