import { render, staticRenderFns } from "./PapasMergeClosedCard.vue?vue&type=template&id=64abcb50&scoped=true&"
import script from "./PapasMergeClosedCard.vue?vue&type=script&lang=js&"
export * from "./PapasMergeClosedCard.vue?vue&type=script&lang=js&"
import style0 from "./PapasMergeClosedCard.vue?vue&type=style&index=0&id=64abcb50&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64abcb50",
  null
  
)

export default component.exports