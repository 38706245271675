<template>
  <div class="account-investigation">
    <div class="close-button-container">
      <img class="close-button" :src="require('@/assets/icons/ic-close.svg')" v-on:click="closeModal">
    </div>
    <div class="cards-container">
      <template v-for="question in accountQuestions">
        <account-investigation-card v-if="!question.answered" class="open-card"
          :key="question.transactionId"
          :question="question"
          @close="closeModal()"/>
        <account-investigation-closed-card v-else :question="question" :key="question.transactionId"/>
      </template>
    </div>
    <riseup-button class="done-btn"
                   :disabled="!noUnansweredAccountQuestions"
                   :action="() => doneAnswering()"
                   :size="'slim'"
                   :title="'סיימתי לענות!'"/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Segment from '../../../../../../../Segment';
import AccountInvestigationCard from './AccountInvestigationCard.vue';
import AccountInvestigationClosedCard from './AccountInvestigationClosedCard.vue';

export default {
  name: 'AccountInvestigation',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    AccountInvestigationCard,
    AccountInvestigationClosedCard,
  },
  created() {
    Segment.trackUserGot('AccountInvestigatorEntered', {
      newAccountsQuestions: this.accountQuestions.length,
    });
  },
  props: {
    onDoneInvestigation: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapGetters('accountInvestigationQuestions', ['noUnansweredAccountQuestions']),
    ...mapState('accountInvestigationQuestions', ['accountQuestions']),
  },
  methods: {
    ...mapActions('accountInvestigationQuestions', ['saveAccountInputs']),
    async closeModal() {
      this.$emit('close');
    },
    async doneAnswering() {
      this.saveAccountInputs();
      this.closeModal();
      Segment.trackUserGot('AccountInvestigationDoneAnswering');
      await this.onDoneInvestigation();
    },
  },
};
</script>

<style lang="scss" scoped>
  .account-investigation {
    text-align: right;
    margin: 16px;

    .close-button-container {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;

      .close-button {
        cursor: pointer;
      }
    }

    .cards-container {
      .open-card {
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
    .done-btn {
      margin-top: 32px;
      width: 100%;
    }
  }
</style>
