<template>
  <div class="bug-report" v-on:click="applyActionAndCloseModal()">
    <span class="text">משהו נראה לא נכון?</span>
    <span class="link">דווחו כאן על תקלה</span>
  </div>
</template>

<script>
export default {
  name: 'CardBugReport',
  props: {
    action: Function,
  },
  methods: {
    applyActionAndCloseModal() {
      this.action();
      this.closeModal();
    },
    async closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .bug-report {
    padding: 24px;
    font-size: 14px;
    text-align: center;
    font-family: SimplerPro;
    cursor: pointer;
    color: $riseup_black;

    .link {
      cursor: pointer;
      font-weight: bold;
    }
  }
</style>
