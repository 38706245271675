<template>
  <div>
    <app-notification :title="'מצאנו עוד חשבון בנק!'"
                      :text="'לדיוק התזרים, נצטרך לדעת האם להשאיר אותו כחלק מהתזרים או להתעלם ממנו.'"
                      :notification-buttons="[
                        {
                          title:'לדיוק התזרים',
                          variant: 'primary',
                          size: 'slim',
                          action: openInvestigation,
                        },
                      ]"
                      :icon-type="'warning'"/>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import Segment from '../../../../../../../Segment';
import AppNotification from '../AppNotification.vue';
import AccountInvestigation from '../../investigations/account-investigation-in-app/AccountInvestigation.vue';

export default {
  name: 'AccountQuestionsNotification',
  components: {
    AppNotification,
  },
  async created() {
    Segment.trackUserGot('AccountQuestionsNotificationShown');
  },
  computed: {
    ...mapGetters('applicationState', ['barTypes', 'notificationTypes']),
  },
  methods: {
    ...mapMutations('applicationState', ['setNotificationState', 'setBarState']),
    ...mapActions('modalRootStore', ['openModal']),
    openInvestigation() {
      this.openModal({
        component: AccountInvestigation,
        props: {
          onDoneInvestigation: this.onDoneInvestigation,
        },
      });
    },
    async onDoneInvestigation() {
      this.setNotificationState(this.notificationTypes.PROCESSING_CASHFLOW);
      this.setBarState(this.barTypes.PROCESSING);
    },
  },
};

</script>

<style lang="scss" scoped>
</style>
